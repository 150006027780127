import eachElement from 'Utils/eachElement.js';

/* Handle mobile navigation */
const setUpNav = () => {
	const navbarBurger = document.querySelector('.navbar-burger');
	const navbarMenu = document.querySelector('.navbar-menu');

	navbarBurger?.addEventListener('click', event => {
		event.preventDefault();

		/* Toggle button appearance */
		navbarBurger?.classList.toggle('is-active');
		navbarMenu?.classList.toggle('is-active');

		/* Toggle menu visibility */
		document.documentElement.classList.toggle('has-menu-active');
		/* Hide any previous submenu */
		document.documentElement.classList.remove('has-submenu-active');

		return false;
	});

	/* Handle mobile nav back button */
	document.querySelector('.navbar-back')?.addEventListener('click', event => {
		event.preventDefault();
		document.documentElement.classList.remove('has-submenu-active');
		return false;
	});

	/* Handle opening mobile submenus */
	eachElement('.navbar-item.has-dropdown > a', element => {
		element.addEventListener('click', event => {
			if (window.innerWidth <= 1023) {
				event.preventDefault();

				/* Hide other submenus */
				eachElement('.navbar-dropdown', dropdown => {
					dropdown.classList.remove('is-active');
				});

				/* Display this submenu */
				element.parentNode.querySelector('.navbar-dropdown')?.classList.add('is-active');

				/* Move to the submenu */
				document.documentElement.classList.add('has-submenu-active');

				return false;
			}
		});
	});
};

document.addEventListener('DOMContentLoaded', () => {
	setUpNav();
});
